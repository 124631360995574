import Image from 'next/image'

import NewsletterForm from '~/components/NewsletterForm/NewsletterForm'
import { Theme, useTheme } from '~/lib/ThemeProvider'

import styles from './AIContent.module.css'

const HEADLINE = 'HTTPie AI 2.0 with Chat coming soon'
const PLACEHOLDER = 'Enter your email to join the waiting list'
const IMAGES = {
  [Theme.DARK]: {
    teaser: '/Images/ai-teaser-dark.svg',
    logo: '/Images/ai-logo-dark.svg',
  },
  [Theme.LIGHT]: {
    teaser: '/Images/ai-teaser.svg',
    logo: '/Images/ai-logo.svg',
  },
}


export const AIContent = () => {
  const { effectiveTheme } = useTheme()
  if (!effectiveTheme) {
    return null
  }
  const { teaser, logo } = IMAGES[effectiveTheme]
  return (
    <div className={styles.content}>
      <Image
        width={400}
        height={400}
        className={styles.pulse}
        src='/Images/ai-pulse-circle.svg'
        alt=''
        draggable={false}
      />
      <div className={styles.left}>
        <img
          width={432}
          height={316}
          className={styles.teaser}
          src={teaser}
          alt='HTTPie AI Chat iteratively building a request and answering questions about HTTP and APIs.'
          draggable={false}
        />
      </div>
      <div className={styles.right}>
        <div className={styles.box}>
          <Image
            width={447}
            height={106}
            className={styles.logo}
            draggable={false}
            src={logo}
            alt='logo'
          />
          <span className={styles.text}>
            {HEADLINE}
          </span>
          <NewsletterForm
            className={styles.newsletter}
            tag='ai'
            variant='ai'
            trackLabel='from-ai'
            data-testid='ai-newsletter-form'
            placeholder={PLACEHOLDER}
          />
        </div>
      </div>
    </div>
  )
}
