import Link from 'next/link'

import Icon from '~/components/Icon/Icon'

import styles from './AIFooter.module.css'

const HTTPIE_AI_PRODUCT_HUNT_URL = 'https://www.producthunt.com/products/httpie-ai#httpie-ai'

export const AIFooter = () => {
  return (
    <footer className={styles.footer}>
      <Link className={styles['product-hunt-link']} href={HTTPIE_AI_PRODUCT_HUNT_URL} target='_blank'>
        <Icon className={styles['product-hunt-icon']} name='product-of-the-day' />
      </Link>
      <Link className={styles.link} href='/blog/ai'>
        Read HTTPie AI 1.0 announcement
        <Icon className={styles['footer-icon']} name='right-arrow-circle' />
      </Link>
    </footer>
  )
}
