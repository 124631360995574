import { Layout } from '~/components/Layout'
import { PageProps } from '~/lib/types'
import { AIContent } from '~/sections/AIContent/AIContent'
import { AIFooter } from '~/sections/AIFooter/AIFooter'

const TITLE = 'HTTPie AI — a new way to interact with APIs'
const DESCRIPTION = 'HTTPie AI 2.0 with Chat is coming soon. Join the waiting list.'
const SHARE_IMAGE = '/Images/Share/ai.png'

const AI: React.FC<PageProps> = ({ layoutProps }) => {
  return (
    <Layout hideFooter {...layoutProps} title={TITLE} titleTemplate={null} shareImage={SHARE_IMAGE} shareDescription={DESCRIPTION}>
      <AIContent />
      <AIFooter />
    </Layout>
  )
}

export default AI
